import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../styles/Projects.css';
import JavaSVG from '../svg/java.js';
import EdgeSVG from '../svg/edge.js';
import AndroidSVG from '../svg/android.js';
import AppleSVG from '../svg/apple.js';
import WordpressSVG from '../svg/wordpress.js';

export const projects = [
  {
    id: 1,
    title: 'Agence Immobilière (JavaFX)',
    description: 'Dans cette section, l\'agence immobilière bénéficie d\'un accès complet à tous les biens mobiliers, incluant leurs pièces et équipements respectifs. Cette fonctionnalité permet à l\'agence de mieux conseiller les clients selon leurs besoins spécifiques, en leur fournissant des informations détaillées sur les biens disponibles.',
    category: 'BTS',
    icon: <JavaSVG />
  },
  {
    id: 2,
    title: 'Agence Immobilière (Web)',
    description: 'Les visiteurs du site ont la liberté de parcourir l\'ensemble des logements disponibles et de procéder à leur réservation ultérieure. Les dates de disponibilité sont établies par l\'équipe commerciale, tandis qu\'ils ont également la possibilité d\'utiliser des codes promotionnels pour bénéficier de réductions lors de la réservation.',
    category: 'BTS',
    icon: <EdgeSVG />
  },
  {
    id: 3,
    title: 'Agence Immobilière (Mobile)',
    description: 'Une fois la réservation effectuée, les clients accèdent à une application mobile pour réaliser l\'état des lieux. Ils peuvent vérifier chaque pièce avec le commercial, exprimer leur accord ou signaler des divergences en capturant une photo et en fournissant un commentaire.',
    category: 'BTS',
    icon: (
      <>
        <AndroidSVG />
        <AppleSVG />
      </>
    )
  },
  {
    id: 4,
    title: 'Idéos Consor',
    description: 'Une entreprise spécialisée dans la formation, cette application propose deux fonctionnalités principales : un questionnaire pour diagnostiquer les problèmes des entreprises et des formations personnalisées en réponse, ainsi qu\'une plateforme de vente d\'entreprises dédiée, similaire à Leboncoin.',
    category: 'Stage',
    icon: (
      <>
        <EdgeSVG />
      </>
    )
  },
  {
    id: 5,
    title: 'Log Connect Services',
    description: 'Mon stage s\'est concentré sur la modernisation du site web existant, le développement d\'une application mobile pour la gestion des interventions des techniciens et le suivi des services par les clients, ainsi que la création d\'un tableau de bord administratif pour faciliter la gestion des ressources humaines et des interventions.',
    category: 'Stage',
    icon: (
      <>
        <WordpressSVG />
        <AndroidSVG />
      </>
    )
  },
  {
    id: 6,
    title: 'CrackZoen',
    description: 'Etant jeune créateur de contenu sur les réseaux (TikTok & Twitch), j\'ai décidé de crée une application Web qui permettra à ma communauté qui me suivent de pouvoir jouer avec moi',
    category: 'Perso',
    icon: (
      <>
        <EdgeSVG />
      </>
    )
  },
  {
    id: 7,
    title: 'ZoWorld',
    description: 'ZoWorld est un projet personnel qui met en avant tous les services que je propose, en offrant des solutions personnalisées pour répondre aux besoins divers de mes clients.',
    category: 'Perso',
    icon: (
      <>
        <WordpressSVG />
        <EdgeSVG />
      </>
    )
  },
];

const Projects = () => {
  const [filter, setFilter] = useState('BTS');

  const filteredProjects = filter === 'Tous' ? projects : projects.filter(project => project.category === filter);

  let subtitle = '';
  switch (filter) {
    case 'BTS':
      subtitle =
        "Au cours de ma formation, j'ai conçu un projet permettant à une agence immobilière de gérer efficacement les propriétés ainsi que leurs pièces et équipements associés. En outre, il offre aux clients la possibilité de réserver aisément tout en facilitant l'établissement des états des lieux dès leur arrivée, le tout à travers trois applications distinctes.";
      break;
    case 'Stage':
      subtitle =
        "Après avoir acquis des compétences grâce à mes études et mes projets personnels, j'ai pu les mettre en œuvre durant mes stages et également en acquérir de nouvelles.";
      break;
    case 'Perso':
      subtitle = "Comme je n'aime pas rien faire, j'ai réalisé plusieurs projets personnels, voici quelques unes, j'ai fais ceci pour continuer à acquérir de nouvelles connaissances.";
      break;
    default:
      subtitle = '';
      break;
  }

  return (
    <section id="projects" className="projects">
      <motion.h2 initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1 }}>
        Projets
      </motion.h2>
      {subtitle && (
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          className="subtitle"
        >
          {subtitle}
        </motion.p>
      )}
      <div className="filter-buttons">
        <button className={`filter-button ${filter === 'Tous' ? 'active' : ''}`} onClick={() => setFilter('Tous')}>
          Tous
        </button>
        <button
          className={`filter-button category-bts ${filter === 'BTS' ? 'active' : ''}`}
          onClick={() => setFilter('BTS')}
        >
          BTS
        </button>
        <button
          className={`filter-button category-stage ${filter === 'Stage' ? 'active' : ''}`}
          onClick={() => setFilter('Stage')}
        >
          Stage
        </button>
        <button
          className={`filter-button category-perso ${filter === 'Perso' ? 'active' : ''}`}
          onClick={() => setFilter('Perso')}
        >
          Perso
        </button>
      </div>
      <div className="projects-list">
        {filteredProjects.map((project) => (

          <motion.div
            className={`project ${project.category.toLowerCase()}`}
            whileHover={{
              scale: 1.1,
              boxShadow: `0 0 40px ${getColorByCategory(project.category)}`,
            }}
            transition={{ duration: 0.3 }}
          >
            <Link key={project.id} to={`/project/${project.id}`} className="project-link">
            <h3>
              {project.title}
              <motion.span
                animate={{ y: [0, 10, 0] }}
                transition={{ duration: 2, repeat: Infinity }}
                style={{
                  display: 'inline-block',
                }}
              >
                {project.icon && <span className="project-icon">{project.icon}</span>}
              </motion.span>
            </h3>
            <p>{project.description}</p>
            </Link>
          </motion.div>
         
        ))}
    </div>
    </section >
  );
};

const getColorByCategory = (category) => {
  switch (category) {
    case 'BTS':
      return 'rgba(255, 60, 0, 0.5)';
    case 'Stage':
      return 'rgba(0, 123, 255, 0.5)';
    case 'Perso':
      return 'rgba(40, 167, 69, 0.5)';
    default:
      return 'rgba(255, 255, 255, 0.5)';
  }
};

export default Projects;
