import React from 'react';
import { motion } from 'framer-motion';
import Header from './Header';
import Footer from './Footer';
import '../styles/MentionsLegales.css';

const PolitiqueDeConfidentialite = () => {
  return (
    <div className="page-container">
      <Header />
      <section className="legal-container">
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="fw-bold mb-4"
        >
          Politique de Confidentialité
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="text-center mb-4"
        >
          Mis à jour le : 24/08/2024
        </motion.p>
        <section>
          <h2>1. Collecte de données personnelles</h2>
          <p>
            Ce site ne collecte pas de données personnelles.
          </p>
        </section>
        <section>
          <h2>2. Utilisation des données collectées</h2>
          <p>
            Aucune donnée personnelle n'est collectée sur ce site, il n'y a donc aucune utilisation de données personnelles.
          </p>
        </section>
        <section>
          <h2>3. Droits d'accès, de rectification et de suppression</h2>
          <p>
            Comme ce site ne collecte pas de données personnelles, il n'y a aucun droit d'accès, de rectification ou de suppression de données personnelles à exercer.
          </p>
        </section>
        <section>
          <h2>4. Cookies</h2>
          <p>
            Ce site utilise des cookies uniquement pour le bon fonctionnement du site. Les cookies ne sont pas utilisés pour collecter des informations personnelles.
          </p>
        </section>
        <section>
          <h2>5. Sécurité des données</h2>
          <p>
            Bien que nous ne collectons pas de données personnelles, nous prenons des mesures raisonnables pour sécuriser notre site contre les accès non autorisés ou les violations de données.
          </p>
        </section>
        <section>
          <h2>6. Modifications de la politique de confidentialité</h2>
          <p>
            Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications seront publiées sur cette page avec une date de mise à jour.
          </p>
        </section>
        <section>
          <h2>7. Contact</h2>
          <p>
            Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter à l'adresse suivante : <a href="mailto:zoworldfr@gmail.com">zoworldfr@gmail.com</a>.
          </p>
        </section>
      </section>
      <Footer />
    </div >
  );
};

export default PolitiqueDeConfidentialite;
