import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { motion } from 'framer-motion';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Projects from './components/Projects';
import ProjectDetail from './components/ProjectDetail';
import Footer from './components/Footer';
import VeilleTechno from './components/VeilleTechno';
import VeilleTechnoDetail from './components/VeilleTechnoDetail';
import MentionsLegales from './components/MentionsLegales';
import PolitiqueDeConfidentialite from './components/PolitiqueDeConfidentialite';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/GlobalStyle.css';
import './App.css';

const App = () => {
  const [showHero, setShowHero] = useState(true);

  const handleStartClick = () => {
    setShowHero(false);
  };

  return (
    <Router>
      <div className="App">
        <main>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  {showHero && <Hero onStartClick={handleStartClick} />}
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: showHero ? 0 : 1 }}
                    transition={{ duration: 1, delay: showHero ? 0 : 0.5 }}
                    style={{ position: 'absolute', width: '100%' }}
                  >
                    <Header />
                    <About id="about" />
                    <hr />
                    <Projects id="projects" />
                    <hr />
                    <VeilleTechno id="veille-techno" />

                    <Footer />
                  </motion.div>
                </>
              }
            />
            
            <Route path="/mentions" element={<MentionsLegales />}/>

            <Route path="/politique"  element={<PolitiqueDeConfidentialite />}/>


            <Route path="/project/:id" element={<>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: showHero ? 0 : 1 }}
                transition={{ duration: 1, delay: showHero ? 0 : 0.5 }}
                style={{ position: 'absolute', width: '100%' }}
              >
                <ProjectDetail />
              </motion.div>
            </>} />


            <Route path="/veille-techno/:id" element={<>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: showHero ? 0 : 1 }}
                transition={{ duration: 1, delay: showHero ? 0 : 0.5 }}
                style={{ position: 'absolute', width: '100%' }}
              >
                <VeilleTechnoDetail />
              </motion.div>
            </>} />

          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
