import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../styles/VeilleTechno.css';
import HTMLSVG from '../svg/html.js';
import PHPSVG from '../svg/php.js';
import Javascript from '../svg/js.js';
import AndroidSVG from '../svg/android.js';
import FlutterSVG from '../svg/flutter.js';
import JavaFX from '../svg/java.js';
import MySQL from '../svg/mysql.js';
import ReactSVG from '../svg/react.js';
import Csharp from '../svg/csharp.js';

export const techs = [
  {
    id: 1,
    title: 'HTML',
    category: 'Web',
    icon: <HTMLSVG />
  },
  {
    id: 2,
    title: 'PHP',
    category: 'Web',
    icon: <PHPSVG />
  },

  {
    id: 3,
    title: 'Android Studio',
    category: 'Mobile',
    icon: <AndroidSVG />
  },
  {
    id: 4,
    title: 'Flutter',
    category: 'Mobile',
    icon: <FlutterSVG />
  },
  {
    id: 5,
    title: 'JavaFX',
    category: 'Bureautique',
    icon: <JavaFX />
  },
  {
    id: 6,
    title: 'MySQL',
    category: 'BDD',
    icon: <MySQL />
  },
  {
    id: 7,
    title: 'Javascript',
    category: 'Web',
    icon: <Javascript />
  },
  {
    id: 8,
    title: 'React',
    category: 'Web',
    icon: <ReactSVG />
  },
  {
    id: 9,
    title: 'React Native',
    category: 'Mobile',
    icon: <ReactSVG />
  },
  {
    id: 10,
    title: 'C#',
    category: 'Bureautique',
    icon: <Csharp />
  }
];


const VeilleTechno = () => {
  const [filter, setFilter] = useState('Web');

  const filteredTechs = filter === 'Tous' ? techs : techs.filter(tech => tech.category === filter);

  const getColorByCategory = (category) => {
    switch (category) {
      case 'Web':
        return 'rgba(0, 123, 255, 0.5)';
      case 'Mobile':
        return 'rgba(255, 60, 0, 0.5)';
      case 'Bureautique':
        return 'rgba(40, 167, 69, 0.5)';
      case 'BDD':
        return 'rgba(97, 48, 230, 0.5)';
      default:
        return 'rgba(255, 255, 255, 0.5)';
    }
  };

  return (
    <section id="veille-techno" className="veille-techno-section">
      <motion.h2
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="fw-bold mb-4 text-center"
      >
        Veille Technologique
      </motion.h2>
      <div className="filter-buttons">
        <button className={`filter-button ${filter === 'Tous' ? 'active' : ''}`} onClick={() => setFilter('Tous')}>
          Tous
        </button>
        <button className={`filter-button category-web ${filter === 'Web' ? 'active' : ''}`} onClick={() => setFilter('Web')}>
          Web
        </button>
        <button className={`filter-button category-mobile ${filter === 'Mobile' ? 'active' : ''}`} onClick={() => setFilter('Mobile')}>
          Mobile
        </button>
        <button className={`filter-button category-bureautique ${filter === 'Bureautique' ? 'active' : ''}`} onClick={() => setFilter('Bureautique')}>
          Bureautique
        </button>
        <button className={`filter-button category-bdd ${filter === 'BDD' ? 'active' : ''}`} onClick={() => setFilter('BDD')}>
          Base de donnée
        </button>
      </div>
      <div className="veille-techno-list">
        {filteredTechs.map((tech) => (
          <motion.div
            key={tech.id}
            className={`veille-techno-item ${tech.category.toLowerCase()}`}
            whileHover={{
              scale: 1.1,
              boxShadow: `0 0 40px ${getColorByCategory(tech.category)}`,
            }}
            transition={{ duration: 0.3 }}
          >
            <Link key={tech.id} to={`/veille-techno/${tech.id}`} className="tech-link">
              <div className="tech-item">
                <h3>
                  {tech.title}
                  <motion.span
                    animate={{ y: [0, 10, 0] }}
                    transition={{ duration: 2, repeat: Infinity }}
                    style={{
                      display: 'inline-block',
                    }}
                  >
                    {tech.icon && <span className="icon-container">{tech.icon}</span>}
                  </motion.span>
                </h3>
              </div>
            </Link>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default VeilleTechno;