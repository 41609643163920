import React from 'react';
import { motion } from 'framer-motion';
import Header from './Header';
import Footer from './Footer';
import '../styles/MentionsLegales.css';

const MentionsLegales = () => {
  return (
    <div className="page-container">
      <Header />
      <section className="legal-container">
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="fw-bold mb-4"
        >
          Mentions Légales
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="text-center mb-4"
        >
          Mis à jour le : 24/08/2024
        </motion.p>
        <section>
          <h2>1. Éditeur du site</h2>
          <p>
            Nom : ZoWorld<br />
            Email : dev@zoworld.fr ou zoworldfr@gmail.com
          </p>
        </section>
        <section>
          <h2>2. Hébergement</h2>
          <p>
            Ce site est hébergé par LWS.<br />
            Adresse : 10 rue Penthièvre, 75008, PARIS<br />
          </p>
        </section>
        <section>
          <h2>3. Propriété intellectuelle</h2>
          <p>
            Le contenu de ce site est protégé par la législation sur la propriété intellectuelle.<br />
            Vous ne pouvez pas reproduire, modifier, transmettre, publier ou distribuer les éléments de ce site sans autorisation préalable.
          </p>
        </section>
        <section>
          <h2>4. Données personnelles</h2>
          <p>
            Ce site ne collecte pas de données personnelles.
          </p>
        </section>
      </section>
      <Footer />
    </div>
  );
};

export default MentionsLegales;
