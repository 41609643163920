import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import { FaGithub, FaLinkedin, FaTiktok, FaEnvelope, FaTree } from 'react-icons/fa';
import '../styles/Footer.css';

const Footer = ({ onScrollToTop }) => {
  const scrollToSection = (sectionName) => {
    scroll.scrollTo(sectionName, {
      smooth: true,
      duration: 500,
    });
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-section links">
              <h2>Liens rapides</h2>
              <ul>
                <li>
                  <a href="/" className="nav_link_header">Accueil</a>
                </li>
                <li>
                  <Link to="about" className="nav_link_header" smooth={true} duration={500} onClick={() => scrollToSection('about')}>
                    About
                  </Link>
                </li>
                <li>
                  <Link to="projects" className="nav_link_header" smooth={true} duration={500} onClick={() => scrollToSection('projects')}>
                    Projects
                  </Link>
                </li>
                <li>
                  <Link to="veille-techno" className="nav_link_header" smooth={true} duration={500} onClick={() => scrollToSection('veille-techno')}>
                    Veille Technologique
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-section legal">
              <h2>Informations Légales</h2>
              <ul>
                <li>
                  <a href="/mentions" className="nav_link_header">Mentions Légales</a>
                </li>
                <li>
                  <a href="/politique" className="nav_link_header">Politique de Confidentialité</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-section social-icons">
              <h2>Me suivre & me contacter</h2>
              <div className="social-links">
                <a href="https://github.com/DeMoNiiAkZz" target="_blank" rel="noopener noreferrer">
                  <FaGithub className="social-icon github-icon" />
                </a>
                <a href="https://www.linkedin.com/in/enzo-cailac-b47b1524a/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin className="social-icon linkedin-icon" />
                </a>
                <a href="https://www.tiktok.com/@crackzoen" target="_blank" rel="noopener noreferrer">
                  <FaTiktok className="social-icon tiktok-icon" />
                </a>
                <a href="https://linktr.ee/CrackZoen" target="_blank" rel="noopener noreferrer">
                  <FaTree className="social-icon linktree-icon" />
                </a>
              </div>
              <div className="email-contact">
                <a href="mailto:zoworldfr@gmail.com" className="email-link">
                  <FaEnvelope className="social-icon email-icon" />
                  <span>zoworldfr@gmail.com</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div className="footer-bottom">&copy; {new Date().getFullYear()} ZoWorld</div>
    </footer>
  );
};

export default Footer;
