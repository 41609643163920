import React, { useState } from 'react';
import { Link } from 'react-scroll';
import '../styles/Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(prevMenuOpen => !prevMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className="header-container">
      <nav className="navbar_header">
        <a href="/" className="logo" onClick={closeMenu}>
          ZoWorld
        </a>
        <div
          className={`menu_container ${menuOpen ? 'active' : ''}`}
          onClick={toggleMenu}
        >
          <div className="hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <ul className={`nav_list_header ${menuOpen ? 'active' : ''}`}>
          <li className="nav_item_header">
            <a href='/' onClick={closeMenu} className="nav_link_header">Accueil</a>
          </li>
          <li className="nav_item_header">
            <Link
              to="about"
              smooth={true}
              onClick={closeMenu}
              className="nav_link_header"
              spy={true}
            >
              À Propos
            </Link>
          </li>
          <li className="nav_item_header">
            <Link
              to="projects"
              smooth={true}
              onClick={closeMenu}
              className="nav_link_header"
              spy={true}
            >
              Projets
            </Link>
          </li>
          <li className="nav_item_header">
            <Link
              to="veille-techno"
              smooth={true}
              onClick={closeMenu}
              className="nav_link_header"
              spy={true}
            >
              Veille Technologique
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default React.memo(Header);
