import React from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { motion } from 'framer-motion';
import '../styles/About.css';
import image from '../img/code.jpg';
import HtmlSVG from '../svg/html.js';
import CssSVG from '../svg/css.js';
import PhpSVG from '../svg/php.js';
import JsSVG from '../svg/js.js';
import ReactSVG from '../svg/react.js';
import FlutterSVG from '../svg/flutter.js';
import LaravelSVG from '../svg/laravel.js';

const About = () => {
  return (
    <section id="about" className="about-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <motion.h2
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="fw-bold mb-4"
            >
              À Propos de Moi
            </motion.h2>
            <motion.p
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
            >
              Jeune passionné par le développement de solutions logicielles web et d'applications. <br /><br />
              Récemment diplômé avec un BTS Services Informatiques aux Organisations (SIO) option Solutions Logicielles et Applications Métiers (SLAM), je suis enthousiaste à l'idée de créer des applications web, bureautiques et mobiles, ainsi que de gérer la communication avec les bases de données. <br /><br />
            </motion.p>
            <motion.p
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
            >
              Au cours de ma formation en BTS SIO, j'ai acquis une variété de compétences dans le domaine du développement logiciel, tant sur le plan technique que pratique. <br /><br />
              J'ai également pu mettre en pratique ces compétences lors de mes stages en entreprise et dans mes projets personnels que vous retrouverez dans mon portfolio.
            </motion.p>
            <br />
            <br />
          </div>
          <div className="col-lg-6">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-12 mb-4 mb-lg-0 text-center">
                <img src={image} alt="Placeholder" className="img-fluid" />
              </div>
              <div className="col-md-4 col-lg-12 text-center">
                <motion.div
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.9 }}
                  className="svg-container"
                  data-tooltip-id="html-tooltip"
                  data-tooltip-content="HTML"
                >
                  <HtmlSVG />
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.9 }}
                  className="svg-container"
                  data-tooltip-id="css-tooltip"
                  data-tooltip-content="CSS"
                >
                  <CssSVG />
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.9 }}
                  className="svg-container"
                  data-tooltip-id="php-tooltip"
                  data-tooltip-content="PHP"
                >
                  <PhpSVG />
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.9 }}
                  className="svg-container"
                  data-tooltip-id="js-tooltip"
                  data-tooltip-content="JavaScript"
                >
                  <JsSVG />
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.9 }}
                  className="svg-container"
                  data-tooltip-id="react-tooltip"
                  data-tooltip-content="React"
                >
                  <ReactSVG />
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="svg-container"
                  data-tooltip-id="flutter-tooltip"
                  data-tooltip-content="Flutter"
                >
                  <FlutterSVG />
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="svg-container"
                  data-tooltip-id="laravel-tooltip"
                  data-tooltip-content="Laravel"
                >
                  <LaravelSVG />
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tooltip id="html-tooltip" />
      <Tooltip id="css-tooltip" />
      <Tooltip id="php-tooltip" />
      <Tooltip id="js-tooltip" />
      <Tooltip id="react-tooltip" />
      <Tooltip id="flutter-tooltip" />
      <Tooltip id="laravel-tooltip" />
    </section>
  );
};

export default About;
