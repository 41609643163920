import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { techs } from './VeilleTechno';
import { motion } from 'framer-motion';
import '../styles/VeilleTechnoDetail.css';

const VeilleTechnoDetail = () => {
  const { id } = useParams();
  const tech = techs.find((t) => t.id === parseInt(id));

  if (!tech) {
    return <div>Technologie non trouvée</div>;
  }

  let detailText = '';

  switch (tech.title) {
    case 'HTML':
      detailText = `
    HTML (HyperText Markup Language) est le langage de balisage standard utilisé pour créer et structurer le contenu des pages Web. <br>
    Développé par Tim Berners-Lee au début des années 1990, HTML permet de définir des éléments variés comme les titres, les paragraphes, les liens, les images, et bien d'autres composants essentiels des sites Web modernes. <br>
    À ses débuts, HTML était rudimentaire, mais il a rapidement évolué pour répondre aux besoins croissants du Web, aboutissant à des versions avancées comme HTML5, qui introduit de nouvelles balises sémantiques, des API pour le multimédia, et une meilleure prise en charge des applications interactives et des jeux. <br>
    Aujourd'hui, HTML est omniprésent et indispensable pour le développement Web, facilitant la création de documents structurés et interconnectés, et restant au cœur de l'expérience Internet.
  `;
      break;
    case 'PHP':
      detailText = `
          PHP (Hypertext Preprocessor) est un langage de script côté serveur largement utilisé pour le développement Web. <br>
          Créé par Rasmus Lerdorf en 1994, PHP permet de créer des pages Web dynamiques et interactives en intégrant directement des scripts dans le HTML. <br>
          Il est particulièrement apprécié pour sa simplicité et sa flexibilité, offrant une vaste gamme de fonctionnalités pour manipuler les bases de données, gérer les sessions, et traiter les formulaires. <br>
          PHP est un élément clé dans le développement de nombreuses applications Web et alimente des millions de sites Web et de plateformes, des blogs personnels aux grands sites de commerce électronique comme Facebook et Wikipedia. <br>
          Son intégration facile avec des bases de données comme MySQL et sa large communauté de développeurs en font un choix populaire pour les projets Web de toutes tailles.
        `;
      break;
    case 'Android Studio':
      detailText = `
          Android Studio est l'environnement de développement intégré (IDE) officiel pour le développement d'applications Android. <br>
          Il offre un ensemble d'outils complets pour coder, déboguer et tester les applications Android. <br>
          Avec des fonctionnalités comme l'éditeur de code intelligent, le système de build basé sur Gradle et l'émulateur Android, Android Studio simplifie et accélère le processus de développement pour les développeurs Android.
        `;
      break;
    case 'Flutter':
      detailText = `
          Flutter est un kit de développement logiciel (SDK) open-source créé par Google. <br>
          Il est utilisé pour développer des applications pour Android, iOS, le Web, et les ordinateurs de bureau à partir d'une seule base de code. <br>
          Flutter se distingue par son architecture réactive et sa capacité à offrir des performances natives tout en permettant une expérience utilisateur cohérente et fluide.
        `;
      break;
    case 'JavaFX':
      detailText = `
          JavaFX est une bibliothèque de graphiques et d'interfaces utilisateur pour Java, utilisée pour créer des applications de bureau riches en fonctionnalités. <br>
          Elle permet de créer des interfaces utilisateur modernes et attrayantes avec des composants graphiques avancés et des effets multimédia. <br>
          JavaFX est souvent utilisée pour développer des applications nécessitant une interface utilisateur sophistiquée et réactive.
        `;
      break;

    case 'MySQL':
      detailText = `
            MySQL est un système de gestion de bases de données relationnelles open source très populaire, utilisé par des millions de développeurs et d'entreprises à travers le monde.<br>
            Développé initialement par MySQL AB, puis acquis par Sun Microsystems et désormais sous la tutelle d'Oracle Corporation, MySQL est connu pour sa robustesse, sa rapidité et sa flexibilité.<br>
            Il utilise le langage de requête structuré (SQL) pour gérer et manipuler les bases de données, permettant ainsi aux utilisateurs de stocker, mettre à jour et récupérer des données de manière efficace.<br>
            MySQL est largement utilisé dans le développement web, notamment avec des applications utilisant le modèle LAMP (Linux, Apache, MySQL, PHP/Python/Perl), et alimente des sites web de toutes tailles, des blogs personnels aux grandes plateformes comme Facebook et Twitter.<br>
            Sa nature open source permet une large communauté de contributeurs et de support, ainsi qu'une adaptabilité aux besoins spécifiques des utilisateurs.
          `;
      break;
    case 'JavaScript':
      detailText = `
    JavaScript est un langage de programmation de haut niveau, interprété, et orienté objet, principalement utilisé pour le développement web. <br>
    Créé en 1995 par Brendan Eich chez Netscape, JavaScript est devenu un pilier essentiel pour les applications web interactives. <br>
    Il permet aux développeurs d'ajouter des fonctionnalités dynamiques aux pages web, telles que la validation des formulaires, la création de graphiques animés, et la mise à jour du contenu sans recharger la page. <br>
    JavaScript est exécuté côté client, ce qui signifie qu'il fonctionne directement dans le navigateur de l'utilisateur, mais il peut également être utilisé côté serveur avec des environnements comme Node.js. <br>
    Sa popularité est renforcée par des frameworks et bibliothèques comme React, Angular, et Vue.js, qui simplifient et accélèrent le développement d'applications web complexes.
  `;
      break;

    case 'React':
      detailText = `
          React est une bibliothèque JavaScript open-source développée par Facebook pour la création d'interfaces utilisateur. <br>
          Introduite en 2013, React facilite la construction de composants d'interface utilisateur réutilisables, permettant aux développeurs de créer des applications web dynamiques et interactives. <br>
          Elle utilise une approche déclarative, ce qui simplifie la gestion de l'état et la mise à jour du DOM (Document Object Model). <br>
          Grâce à son utilisation du Virtual DOM, React offre des performances optimisées en mettant à jour uniquement les parties de l'interface utilisateur qui ont changé. <br>
          Elle est largement adoptée dans l'industrie et supportée par une vaste communauté, ainsi que par un écosystème riche de bibliothèques et d'outils complémentaires.
        `;
      break;
    case 'React Native':
      detailText = `
          React Native est un framework open-source développé par Facebook pour créer des applications mobiles en utilisant JavaScript et React. <br>
          Lancé en 2015, React Native permet aux développeurs de construire des applications mobiles pour iOS et Android avec une seule base de code, en utilisant les mêmes principes que React. <br>
          Le framework permet de rendre des composants natifs, offrant une performance proche de celle des applications développées nativement. <br>
          Grâce à sa large adoption et à sa communauté active, React Native bénéficie d'une multitude de bibliothèques et de modules qui simplifient le développement et ajoutent des fonctionnalités avancées aux applications mobiles. <br>
          Il permet également une itération rapide grâce à des fonctionnalités comme le rechargement à chaud, facilitant ainsi le développement et le débogage.
        `;
      break;
    case 'C#':
      detailText = `
            C# est un langage de programmation moderne et puissant développé par Microsoft. Il est principalement utilisé pour développer des applications sur la plateforme Microsoft .NET. C# combine les meilleures caractéristiques de C++ et de Java tout en ajoutant des fonctionnalités spécifiques à .NET, comme la gestion automatique de la mémoire. Il est largement utilisé pour créer des applications Windows, des applications Web, des services cloud, des jeux, des applications mobiles et bien plus encore.
        `;
      break;
    default:
      detailText = '';
      break;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <motion.div
            className="veille-techno-detail"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Link to="/" className="back-link">
              &lt; Retour à l'accueil
            </Link>
            <motion.h2
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="tech-title mt-5"
            >
              {tech.title} - {tech.category}
              <motion.div
                className="icon-container"
                animate={{ y: [0, -5, 0, 5, 0], opacity: [1, 0.8, 1, 0.8, 1] }}
                transition={{ duration: 2, repeat: Infinity, ease: 'easeInOut' }}
              >
                {tech.icon}
              </motion.div>
            </motion.h2>
            <p className='mt-5' dangerouslySetInnerHTML={{ __html: detailText }} />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default VeilleTechnoDetail;
