import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';
import '../styles/Hero.css';

const Hero = ({ onStartClick }) => {
  const [heroVisible, setHeroVisible] = useState(true);

  useEffect(() => {
    const handleStart = () => {
      setHeroVisible(false);
      onStartClick();
    };

    const startButton = document.getElementById('startButton');
    if (startButton) {
      startButton.addEventListener('click', handleStart);
    }

    return () => {
      if (startButton) {
        startButton.removeEventListener('click', handleStart);
      }
    };
  }, [onStartClick]);

  return (
    <motion.section
      id="hero"
      className={`hero ${!heroVisible ? 'hide' : ''}`}
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >

      <motion.h1 initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        Bienvenue sur mon portfolio
      </motion.h1>
      <br />
      <motion.p initial={{ x: '-100vw' }} animate={{ x: 0 }} transition={{ duration: 1 }}> Transformez vos idées en solutions numériques puissantes : Développeur Web et Applications, expert en gestion de bases de données, et gardien de votre cybersécurité. </motion.p>
      <br /><br />
      <Link to="about" smooth={true} duration={500}>
        <motion.button
          id="startButton"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 0.5 }}
          className="start-button"
        >
          Démarrer
        </motion.button>
      </Link>
    </motion.section>
  );
};

export default Hero;
