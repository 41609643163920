import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { projects } from './Projects';
import '../styles/ProjectDetail.css';

const ProjectDetail = () => {
  const { id } = useParams();
  const project = projects.find((p) => p.id === parseInt(id));

  if (!project) {
    return <div>Projet non trouvé</div>;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <motion.div
            className="project-detail"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Link to="/" className="back-link">
              &lt; Retour à l'accueil
            </Link>
            <motion.h2
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="project-title mt-5"
            >
              {project.title}
              <motion.div
                className="project-icon"
                animate={{ y: [0, -5, 0, 5, 0], opacity: [1, 0.8, 1, 0.8, 1] }}
                transition={{ duration: 2, repeat: Infinity, ease: 'easeInOut' }}
              >
                {project.icon}
              </motion.div>
            </motion.h2>
            <p className='mt-5'>{project.description}</p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;
